import axios from 'axios'
const { BASE_URL } = require('./config')
axios.defaults.baseURL = BASE_URL

axios.interceptors.request.use((config) => {
  return config
})

axios.interceptors.response.use((response) => {
  return response.data
})

export const get = (url, params) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })

export const post = (url, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
