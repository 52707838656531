<template>
  <div class="mint-item">
    <div class="mint-price">
      <span class="price">{{ price }}</span> ETH / Each
    </div>
    <input
      ref="mintInput"
      class="mint-input"
      type="text"
      v-show="mintMethod === MINT_PUBLIC"
      v-model="mintNum"
    />
    <div class="mint-btn" @click="emitMint">MINT</div>
  </div>
</template>

<script>
const { MINT_PUBLIC } = require('../config')
export default {
  props: {
    price: Number | String,
    mintMethod: String,
    canMintMax: Number,
  },
  watch: {
    account(val) {
      console.log('account change:', val, this.mintNum)
      this.mintNum = val ? 1 : 0
      console.log(this.mintNum, 'after=====')
    },
    canMintMax(val) {
      if (val < this.mintNum) this.mintNum = val
    },
    mintNum(val, oval) {
      if (/^[1-9]\d*$/.test(val)) {
        if (val > this.canMintMax) {
          this.mintNum = this.canMintMax
        }
      } else {
        if (val !== '') {
          this.mintNum = oval
        }
      }
    },
  },
  computed: {
    account() {
      return this.$parent.account
    },
  },
  methods: {
    emitMint() {
      let mintNum = 1
      if (this.mintMethod === 'mintpublic') {
        mintNum = this.mintNum
      }
      this.$emit('mint', {
        mintMethod: this.mintMethod,
        mintNum: Math.min(mintNum, this.canMintMax),
      })
    },
    resetMintNum() {
      this.mintNum = 1
    },
  },
  data() {
    return {
      MINT_PUBLIC,
      mintNum: 1,
    }
  },
}
</script>

<style lang="scss" scoped>
.mint {
  &-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: px2rem(26);
    margin-top: px2rem(25);
  }
  &-price {
    width: px2rem(260);
    .price {
      color: #ee3135;
    }
  }
  &-input {
    padding: px2rem(8) px2rem(8);
    border-radius: px2rem(5);
    color: #fff;
    text-align: center;
    font-size: px2rem(20);
    width: px2rem(100);
    @include bg;
    background-image: url('../assets/mint.png');
    background-color: transparent;
    background-size: 100% 100%;
  }
  &-btn {
    width: px2rem(190);
    height: px2rem(50);
    @include bg;
    background-image: url('../assets/mint.png');
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
