<template>
  <div id="app" class="home-page">
    <div class="home-header">
      <div class="home-header-container">
        <img
          @click="goToHome"
          :src="require('./assets/logo.png')"
          alt=""
          class="logo-img"
        />
        <div class="icon-box">
          <img
            v-for="(item, i) in iconList"
            :key="i"
            :src="item.src"
            alt=""
            @click="goToLink(item.href)"
            class="icon-img"
          />
          <img
            class="icon-img icon-fox"
            :src="require('./assets/metamask.png')"
            alt=""
            v-if="account"
          />
          <img
            v-else
            :src="require('./assets/wallet.png')"
            alt=""
            @click="connectWallet"
            class="icon-img icon-wallet"
          />
          <span class="user-account regular">{{ showAccount }}</span>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- <img :src="require('./assets/img.png')" alt="" class="img-main" /> -->
      <div class="video-box">
        <video src="/videos/video_raw.mp4" muted loop autoplay></video>
      </div>
      <div class="container-box">
        <div class="container-title ff-bold">Tori Zero - figure</div>
        <div class="container-desc ff-regular">
          <p class="container-desc-item">
            It has been a year since the launch of Tori Zero NFT.
          </p>
          <p class="container-desc-item">
            12 months ago, Tori Zero was born from her production pod, which is
            also displayed in the cover picture before NFTs were revealed.
          </p>
          <p class="container-desc-item">
            After a year since her birth, we are thrilled to introduce you to
            the physical production pod of Tori Zero.
          </p>
          <p class="container-desc-item">
            This NFT mint serves as a redeemable voucher, which will allow
            holders to trade it in for a physical collectible figure.
          </p>
          <p class="container-desc-item">
            All holders in our snapshot on March 31st are eligible for a private
            sale round on April 24th to 27th. The public sale will commence on
            April 28th (UTC+0).
          </p>
          <p class="container-desc-item">
            Discount vouchers are available now in the Tori Zero marketplace.
          </p>
          <p class="container-desc-item">
            Burning and exchange details will be announced after the mint.
          </p>
        </div>
        <div class="container-mintbox ff-regular">
          <div class="mint-unstart" v-if="currentStep === 0">
            Mint not started.
          </div>
          <div class="mint-step1" v-else-if="currentStep === 1">
            <div class="mint-infobox">
              <div class="remain">{{ stepMinted }} / {{ stepAmount }}</div>
              <div class="info">Whitelist Phase - One for each wallet</div>
            </div>
            <div class="mint-mintbox">
              <!-- <mint-item
                mint-method="mintWl0"
                :price="mintPrice - 0.01"
                :can-mint-max="canMintMax"
                @mint="mint($event)"
              ></mint-item> -->
              <mint-item
                ref="mintWl1"
                mint-method="mintWl1"
                :price="mintPrice"
                :can-mint-max="canMintMax"
                @mint="mint($event)"
              ></mint-item>
            </div>
          </div>
          <div class="mint-step2" v-else-if="currentStep === 2">
            <div class="mint-infobox">
              <div class="remain">{{ stepMinted }} / {{ stepAmount }}</div>
              <div class="info">Public Phase - Unlimited</div>
            </div>
            <mint-item
              ref="mintpublic"
              mint-method="mintpublic"
              :price="mintPrice"
              :can-mint-max="canMintMax"
              @mint="mint($event)"
            ></mint-item>
          </div>
        </div>
        <div class="container-burnbox ff-regular">
          <div class="burn" @click="getBurnList">BURNING RECORD</div>
          <div class="burn" @click="getAwardInfo">Shipment Info.</div>
          <div class="burn" @click="chooseBurnList">BURN</div>
        </div>
      </div>
    </div>
    <div class="msg-modal ff-regular" v-if="showErrorBox">
      <div class="msg-content">{{ errorText }}</div>
      <div class="msg-btn" @click="showErrorBox = false">Confirm</div>
    </div>
    <div class="award-box" v-if="showAwardModal">
      <div class="award-close" @click="showAwardModal = false"></div>
      <div class="award-maintitle ff-bold">Shipment</div>
      <div class="award-info">
        In order to provide you with high-quality services and accurately
        deliver Tori Zero figures to you, please fill in the following
        information truthfully
      </div>
      <div class="award-content">
        <div class="award-item">
          <div class="award-title ff-bold">Country:</div>
          <div class="award-country-box">
            <input
              class="award-input"
              type="text"
              v-model="awardInfo.country"
              @focus="showCountry = true"
            />
            <div class="award-select" v-show="showCountry">
              <div
                class="award-select-item"
                :class="{ active: item.name === awardInfo.country }"
                v-for="item in rawCountryList"
                :key="item.name"
                @click="chooseCountry(item.name)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="award-item">
          <div class="award-title ff-bold">Address:</div>
          <input class="award-input" type="text" v-model="awardInfo.address" />
        </div>
        <div class="award-item">
          <div class="award-title ff-bold">Name:</div>
          <input class="award-input" type="text" v-model="awardInfo.name" />
        </div>
        <div class="award-item">
          <div class="award-title ff-bold">Phone:</div>
          <input class="award-input" type="text" v-model="awardInfo.phone" />
        </div>
        <div class="award-item">
          <div class="award-title ff-bold">Zip Code:</div>
          <input class="award-input" type="text" v-model="awardInfo.zipCode" />
        </div>
        <div class="award-item">
          <div class="award-title">&nbsp;</div>
          <div class="award-btn" @click="submit">SUBMIT</div>
        </div>
      </div>
    </div>
    <!-- unburn nft list -->
    <burn-item
      :nft-list="nftList"
      @choose="choose($event)"
      @burn="burn"
      title="Nft List"
      v-model="showBurnModal"
    ></burn-item>
    <!-- burn records -->
    <info-box
      :show="showBurnLogModal"
      @close="showBurnLogModal = false"
      title="Burn Record"
    >
      <div class="burn-log" v-for="(item, i) in burnList" :key="i">
        <div># {{ item.tokenId }}</div>
        <div>{{ timeFormat(item.time) }}</div>
      </div>
    </info-box>
  </div>
</template>

<script>
import $BN from 'bignumber.js'
import Web3 from 'web3'
import { get, post } from './axios'
import mintItem from './components/MintItem.vue'
import burnItem from './components/BurnItem.vue'
import infoBox from './components/InfoBox.vue'
const ABI = require('./abi')
const rawCountryList = require('./country.json')

const {
  CHAIN_INFO,
  mintInfo,
  SHOW_CONSOLE,
  TWITTER_URL,
  DISCORD_URL,
  OPENSEA_URL,
  MINT_PUBLIC,
  INFO_METHOD,
  BASE_PROOF_URL,
  SERVER_URL,
} = require('./config')
export default {
  name: 'App',
  components: {
    mintItem,
    burnItem,
    infoBox,
  },
  created() {
    this.initWeb3()
  },
  watch: {
    async account(val) {
      this.resetData()
      if (val) {
        this.infoInterval = true
        this.getInfo(true)
        // this.getAssetsList()
      } else {
        this.infoInterval = false
      }
    },
  },
  computed: {
    countryList() {
      const country = this.awardInfo.country.toLowerCase()
      return this.rawCountryList.filter(
        (item) => item.name.toLowerCase().indexOf(country) > -1
      )
      // return this.rawCountryList.filter(
      //   (item) =>
      //     (item.name || '')
      //       .toLowerCase()
      //       .indexOf(this.awardInfo.country.toLowerCase()) > -1
      // )
    },
    showAccount() {
      if (!this.account) return ''
      const account = this.account
      const len = this.account.length
      return `${account.substring(0, 5)}...${account.substring(len - 4)}`
    },
    // can mint max
    canMintMax() {
      return Math.min(
        this.userMaxMint - this.userMinted,
        this.totalCount - this.totalMinted,
        this.stepAmount - this.stepMinted
      )
    },
    //
    nftList() {
      return this.rawNftList.map((item) => ({
        tokenId: item.tokenId,
        image: item.imageUrlOriginal,
        checked: item.checked,
      }))
    },
    // total mint money
    // mintMoney() {
    //   if (Number(this.mintNum) === 0 || Number(this.mintPrice) === 0) return '0'

    //   // let mintNum = /^(\d+)$/.test(this.mintNum) ? this.mintNum : 0

    //   // if (this.userMinted === 0) mintNum -= 1

    //   return new $BN(this.mintPrice).times(this.mintNum).toString()
    // },
    canMint() {
      return Boolean(this.account && this.canMintMax > 0)
    },
  },
  data() {
    return {
      showMintModal: false,
      showBurnModal: false,
      showAwardModal: false,
      showBurnLogModal: false,
      web3: null,
      contractObj: null,
      claimContractObj: null,
      TWITTER_URL,
      OPENSEA_URL,
      ...CHAIN_INFO,
      account: '',
      defaultAccount: '0x0000000000000000000000000000000000000000',
      totalCount: 5555,
      mintNum: 1,
      showErrorBox: false,
      errorTitle: '',
      errorText: '',
      ...mintInfo,
      infoInterval: true,
      infoTimer: null,
      rawCountryList,
      showCountry: false,
      iconList: [
        {
          src: require('./assets/opensea.png'),
          href: OPENSEA_URL,
        },
        {
          src: require('./assets/discord.png'),
          href: DISCORD_URL,
        },
        {
          src: require('./assets/twitter.png'),
          href: TWITTER_URL,
        },
      ],
      awardInfo: {
        country: '',
        name: '',
        address: '',
        phone: '',
        zipCode: '',
      },
      burnList: [],
      rawNftList: [],
    }
  },
  methods: {
    chooseCountry(country) {
      this.$set(this.awardInfo, 'country', country)
      this.showCountry = false
    },
    goToHome() {
      location.href = 'https://torizero.com'
    },
    resetData() {
      this.$set(this, 'rawNftList', [])
      this.$set(this, 'burnList', [])
      this.$set(this, 'awardInfo', {
        country: '',
        name: '',
        address: '',
        phone: '',
        zipCode: '',
      })
      this.showBurnModal = false
      this.showAwardModal = false
      this.showBurnLogModal = false
    },
    async getAssetsList() {
      const { assetsList } = await post('/nft/getAssetsList', {
        assetContractAddress: this.contractAddress,
        owner: this.account,
      })
      this.$set(
        this,
        'rawNftList',
        (assetsList || []).map((item) => ({
          tokenId: item.tokenId,
          imageUrlOriginal: item.imageUrlOriginal,
          checked: false,
        }))
      )
    },
    goToLink(url) {
      if (!url) return
      window.open(url, '_target')
    },
    log(...args) {
      SHOW_CONSOLE && console.log(...args)
    },
    async getInfo(bool = false) {
      if (!this.contractObj) return
      if (!this.contractObj) this.contractObj = await this.initContract()
      if (this.infoTimer) clearTimeout(this.infoTimer)
      const info = await this.readContract(this.contractObj, INFO_METHOD, [
        this.account || this.defaultAccount,
      ])
      this.log(info)
      this.totalCount = Number(info[0])
      this.totalMinted = Number(info[1])
      this.mintPrice = this.web3.utils.fromWei(info[2])
      this.userMinted = Number(info[4])
      this.currentStep = Number(info[5])
      this.userMaxMint = Number(info[6])
      this.stepMinted = Number(info[7])
      this.stepAmount = Number(info[8])
      if (bool) this.mintNum = this.canMintMax
      if (this.infoInterval) {
        this.infoTimer = setTimeout(() => {
          this.getInfo(false)
        }, 2000)
      }
    },
    async getBurnList() {
      if (!this.account) {
        this.setErrorInfo({ text: 'Please connect wallet.' })
        return
      }

      this.closeModal()
      this.showBurnLogModal = true
      const { message } = await post(`${SERVER_URL}`, {
        code: 1001,
        message: JSON.stringify({ from: this.account }),
      })
      const { list } = JSON.parse(message)
      this.$set(this, 'burnList', list)
    },
    timeFormat(time) {
      const d = new Date(time * 1000)
      const year = d.getFullYear()
      const month = d.getMonth() + 1
      const day = d.getDate()

      const hour = d.getHours()
      const mins = d.getMinutes()
      const secs = d.getSeconds()
      function padStart(num) {
        return `${num}`.padStart(2, '0')
      }
      return `${year}-${padStart(month)}-${padStart(day)} ${padStart(
        hour
      )}:${padStart(mins)}:${padStart(secs)}`
    },
    // get award info
    async getAwardInfo() {
      if (!this.account) {
        this.setErrorInfo({ text: 'Please connect wallet.' })
        return
      }
      this.closeModal()
      this.showAwardModal = true
      const { message } = await post(`${SERVER_URL}`, {
        code: 1002,
        message: JSON.stringify({ from: this.account }),
      })
      const {
        country = '',
        address = '',
        name = '',
        zipCode = '',
        phone = '',
      } = JSON.parse(message).walletAddress || {}
      this.$set(this, 'awardInfo', { country, address, name, zipCode, phone })
    },
    // mint
    async mint({ mintMethod, mintNum }) {
      if (!this.canMint) return
      const hasMetamask = await this.checkMetamask()
      if (!hasMetamask) return
      if (!this.account) {
        this.setErrorInfo({
          text: 'Please connect the wallet first',
          title: '',
        })
        return
      }

      if (!this.contractObj) this.contractObj = await this.initContract()

      if (mintNum < 1) {
        this.setErrorInfo({
          text: 'mint amount must be at least 1',
          title: '',
        })
        return
      }

      let proof = []
      if (mintMethod !== MINT_PUBLIC) {
        proof = await this.getProofList(mintMethod === 'mintWl0' ? 1 : 2)
        if (proof.length === 0) {
          this.setErrorInfo({ text: 'You are not whitelisted' })
          return
        }
      }
      const mintParams = [mintNum]
      if (mintMethod !== MINT_PUBLIC) {
        mintParams.push(proof)
      }
      let mintPrice = this.mintPrice
      if (mintMethod === 'mintWl0') {
        mintPrice = new $BN(`${mintPrice}`).minus('0.01').toString()
      }

      const mintMoney = this.web3.utils.toWei(
        new $BN(mintPrice).times(mintNum).toString()
      )

      this.listenTransfer(this.defaultAccount, this.account, () => {
        this.setErrorInfo({ text: 'Mint Success.', title: 'Mint' })
      })
      await this.writeContract(this.contractObj, mintMethod, mintParams, {
        value: mintMoney,
        from: this.account,
      })
    },

    listenTransfer(from, to, callback) {
      this.contractObj.once(
        'Transfer',
        {
          filter: { from, to },
        },
        (err, event) => {
          if (err) {
            console.log(err)
            return
          }
          callback && callback()
        }
      )
    },

    async choose(tokenId) {
      const fIndex = this.rawNftList.findIndex(
        (item) => item.tokenId === tokenId
      )
      this.$set(
        this.rawNftList[fIndex],
        'checked',
        !this.rawNftList[fIndex].checked
      )
    },
    closeModal() {
      this.showBurnModal = false
      this.showAwardModal = false
      this.showBurnLogModal = false
    },
    chooseBurnList() {
      if (!this.account) {
        this.setErrorInfo({ text: 'Please connect wallet.' })
        return
      }
      this.closeModal()
      this.showBurnModal = true
      this.getAssetsList()
    },
    // burn NFT
    async burn() {
      const tokenIds = this.nftList
        .filter((item) => item.checked)
        .map((o) => o.tokenId)
      if (tokenIds.length === 0) {
        this.setErrorInfo({ text: 'Please select the NFT you want to burn' })
        return
      }
      this.listenTransfer(this.account, this.defaultAccount, () => {
        this.setErrorInfo({ text: 'Burn Success.' })
      })
      await this.writeContract(this.contractObj, 'burn', [tokenIds], {
        from: this.account,
      })
    },
    //
    async getProofList(step = 1) {
      const { correctProof } = await get(
        `${BASE_PROOF_URL}/common/getProofList`,
        {
          id: this.account.toLowerCase(),
          step,
        }
      )
      return correctProof
      // return []
    },
    // init web3
    async initWeb3() {
      let web3Provider = null
      if (window.ethereum) {
        web3Provider = window.ethereum
        // linstener account changed
        window.ethereum.on('accountsChanged', (accounts) => {
          const [account] = accounts
          this.account = account
        })
        // listener chain id change
        window.ethereum.on('chainChanged', (chainId) => {
          if (parseInt(chainId) === this.CHAIN_ID) {
            this.currentChainId = chainId
          }
        })
      } else if (this.web3) {
        web3Provider = ethereum
      } else {
        web3Provider = new Web3.providers.HttpProvider(this.rpcURL)
      }
      this.web3 = new Web3(web3Provider)
      this.contractObj = await this.initContract()
      this.getAccounts()
      // this.claimContractObj = await this.initClaimContract()
      const curChainId = await this.web3.eth.net.getId()
      await this.getInfo(true)
      if (curChainId === this.CHAIN_ID) this.currentChainId = curChainId
      await this.checkAndSwitchChainId()
    },
    // init contract
    async initContract() {
      if (!this.contractAddress) return
      if (!this.web3) await this.initWeb3()
      if (this.curChainId !== this.CHAIN_ID) {
        await this.checkAndSwitchChainId()
      }
      return new Promise((resolve) => {
        resolve(new this.web3.eth.Contract(ABI, this.contractAddress))
      })
    },

    // async initClaimContract() {
    //   if (!claimContractAddress) return
    //   if (!this.web3) await this.initWeb3()
    //   if (this.curChainId !== this.CHAIN_ID) {
    //     await this.checkAndSwitchChainId()
    //   }
    //   return new Promise((resolve) => {
    //     resolve(new this.web3.eth.Contract(CLAIM_ABI, claimContractAddress))
    //   })
    // },

    async checkMetamask() {
      return new Promise((resolve) => {
        if (!window.ethereum) {
          this.showErrorBox = true
          this.setErrorInfo({
            text: 'Please install metakask.',
            title: 'Check Metamask',
          })
          resolve(false)
        } else {
          resolve(true)
        }
      })
    },
    async connectWallet() {
      const hasMetamask = await this.checkMetamask()

      if (!hasMetamask) return

      if (!this.web3) await this.initWeb3()

      await this.checkAndSwitchChainId()

      this.web3.eth
        .requestAccounts()
        .then((accounts) => {
          const [account] = accounts
          this.account = account.toLowerCase()
        })
        .catch((err) => {
          this.log(err.message)
          this.setErrorInfo({
            text: err.message,
            title: '',
          })
        })
    },
    setErrorInfo({ text = '', title = '' }) {
      this.errorText = text
      this.errorTitle = title
      this.showErrorBox = true
    },
    async readContract(contract, methodName, params, { errMsg = null } = {}) {
      const startReadTime = new Date().getTime()
      this.log(
        `read contract [${methodName}]`,
        { contract, methodName, params },
        startReadTime
      )
      return new Promise((resolve, reject) => {
        try {
          contract.methods[methodName].apply(this, params).call((err, data) => {
            if (!err) {
              const endReadTime = new Date().getTime()
              this.log(
                `read contract [${methodName}] data:`,
                { contract, params, data },
                endReadTime,
                `total time: ${endReadTime - startReadTime}ms`
              )
              resolve(data)
              return
            }
            this.log(`read contract [${methodName}] error`, err)
            if (err.code === -32603) {
              this.log(`network error. [${methodName}]`)

              this.setErrorInfo({
                text: err.message,
                title: '',
              })
              // showErrMsg(`network error. [${methodName}]`)
            } else {
              this.log(err, 'read error----')
              this.setErrorInfo({
                text: errMsg || err.message,
                title: '',
              })
              // showErrMsg(errMsg || err.message)
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              contractCode: -1,
              data: err,
            })
          })
        } catch (error) {
          this.log(error.message)
          this.setErrorInfo({
            text: error.message,
            title: '',
          })
          reject({
            contractCode: -1,
            data: error,
          })
        }
      })
    },
    async writeContract(contract, methodName, params = [], opt = {}) {
      const startWriteTime = new Date().getTime()
      this.log(
        `write contract [${methodName}]`,
        { contract, params, opt },
        startWriteTime
      )
      return new Promise((resolve, reject) => {
        try {
          contract.methods[methodName]
            .apply(this, params)
            .send(opt, (err, data) => {
              if (!err) {
                const endWriteTime = Date.now()
                this.log(
                  `write contract [${methodName}] success:`,
                  { contract, params, opt },
                  data,
                  endWriteTime,
                  `total time: ${endWriteTime - startWriteTime}ms`
                )
                resolve(data)
                return
              }
              if (err.code === 4001) {
                this.setErrorInfo({
                  text: err.message.replace('MetaMask Tx Signature:', ''),
                  title: '',
                })
                // showErrMsg(err.message)
              } else if (err.code === -32603) {
                this.setErrorInfo({
                  text: err.message,
                  title: '',
                })
                // showErrMsg(err.message)
              } else {
                SHOW_CONSOLE &&
                  console.error(`write contract [${methodName}] err:`, err)
              }
              reject(err)
            })
        } catch (error) {
          this.log(`write contract [${methodName}] error`, error)
          this.setErrorInfo({
            text: error.message,
            title: '',
          })
          // showErrMsg(`write contract [${methodName}] error`)
          // reject(error.message)
        }
      })
    },
    async checkAndSwitchChainId() {
      return new Promise(async (resolve) => {
        const curChainId = await this.web3.eth.net.getId()
        if (curChainId === this.CHAIN_ID) return resolve()
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: this.web3.utils.numberToHex(this.CHAIN_ID),
              },
            ],
          })
          this.currentChainId = this.CHAIN_ID
        } catch (e) {
          if (e.code === 4902) {
            await web3.currentProvider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: this.web3.utils.numberToHex(this.CHAIN_ID),
                  chainName: '',
                  rpcUrls: [this.rpcURL],
                },
              ],
            })
            this.currentChainId = this.CHAIN_ID
            this.setErrorInfo({
              text: '',
              title: '',
            })
          } else if (e.code === -32002) {
            this.setErrorInfo({
              text: 'The chain cut request already exists, please process it',
              title: '',
            })
          } else {
            this.setErrorInfo({
              text: e.message,
              title: '',
            })
          }
          this.showErrorBox = true
        }
        resolve()
      })
    },
    async getAccounts() {
      if (!this.web3) await this.initWeb3()
      const [account] = await this.web3.eth.getAccounts()
      this.account = account || ''
    },
    async submit() {
      if (!this.awardInfo.name) {
        this.setErrorInfo({ text: 'Please fill in your name' })
        return
      }
      if (!this.awardInfo.address) {
        this.setErrorInfo({ text: 'Please fill in your address' })
        return
      }
      if (!this.awardInfo.phone) {
        this.setErrorInfo({ text: 'Please fill in your phone' })
        return
      }
      if (!this.awardInfo.zipCode) {
        this.setErrorInfo({ text: 'Please fill in your Zip Code' })
        return
      }
      const { errorCode } = await post(`${SERVER_URL}`, {
        code: 1003,
        message: JSON.stringify({
          from: this.account,
          ...this.awardInfo,
        }),
      })
      if (errorCode !== '0') {
        this.setErrorInfo({
          text: '',
        })
      }
      if (errorCode === '0') {
        this.showAwardModal = false
        this.setErrorInfo({ text: 'Submit Success.' })
      }
    },
  },
}
</script>

<style lang="scss">
.header {
  background: $headerBg;
}
</style>

<style lang="scss" scoped>
.home {
  &-header {
    background: $headerBg;
    height: px2rem($headerHeight);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    &-container {
      width: px2rem(1220);
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.icon {
  &-box {
    display: flex;
    align-items: center;
  }
  &-img {
    margin-left: px2rem(20);
    height: px2rem(22);
    display: block;
    cursor: pointer;
  }
  &-wallet {
    height: px2rem(30);
    margin-left: px2rem(20);
  }
  &-fox {
    margin-right: px2rem(10);
  }
}
.logo-img {
  height: px2rem(28);
  cursor: pointer;
}
#app {
  height: calc(100% - px2rem($headerHeight));
  padding-top: px2rem($headerHeight);
  @include bg;
  background-image: url('./assets/bg.jpg');
}
.container {
  display: flex;
  align-items: center;
  height: calc(100% - px2rem($headerHeight));
  padding: 0 px2rem(68) 0 px2rem(22);
  &-box {
    display: flex;
    flex-direction: column;
    padding: px2rem(24) px2rem(30) px2rem(35) px2rem(64);
    height: 100%;
  }
  &-title {
    font-size: px2rem(36);
    color: #fff;
    height: px2rem(65);
    line-height: px2rem(65);
    padding-left: px2rem(28);
    width: px2rem(476);
    background: linear-gradient(90deg, #3a0c0f, #000);
  }
  &-desc {
    font-size: px2rem(18);
    color: $fontColor;
    &-item {
      margin-top: px2rem(20);
    }
  }
  &-burnbox {
    display: flex;
    justify-content: flex-end;
    margin-top: px2rem(20);
    font-size: px2rem(18);
    .burn {
      color: #fff;
      width: px2rem(190);
      height: px2rem(50);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: px2rem(10);
      cursor: pointer;
      @include bg;
      background-image: url('./assets/burn.png');
      background-size: 100% 100%;
    }
  }
  &-mintbox {
    background: rgba(0, 0, 0, 0.7);
    position: relative;
    color: #d8d8d8;
    font-size: px2rem(32);
    margin-top: px2rem(50);
    height: px2rem(238);
    $border-color: #e6313d;
    $border-width: px2rem(2);
    // border: 1px solid blue;
    padding: px2rem(20) px2rem(15);
    display: flex;
    align-items: center;
    justify-content: center;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: px2rem(30);
      height: px2rem(30);
      border: $border-width solid transparent;
    }
    &::before {
      left: -$border-width;
      top: -$border-width;
      border-left-color: $border-color;
      border-top-color: $border-color;
    }
    &::after {
      right: -$border-width;
      bottom: -$border-width;
      border-right-color: $border-color;
      border-bottom-color: $border-color;
    }
  }
}
.user-account {
  color: #fff;
  font-size: px2rem(18);
}
.img {
  &-main {
    width: px2rem(700);
    display: block;
  }
}
.video {
  &-box {
    width: px2rem(700);
    height: px2rem(710);
    position: relative;
    overflow: hidden;
    > video {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      height: px2rem(710);
      transform: translate(-50%, -50%);
    }
  }
}
.mint {
  &-infobox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .remain {
      text-align: center;
      width: px2rem(260);
    }
  }
  &-unstart {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.msg {
  &-modal {
    color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #3d3d3d;
    border: px2rem(2) solid #898888;
    z-index: 40;
    width: px2rem(600);
    min-height: px2rem(400);
    padding-bottom: px2rem(60);
    display: flex;
    flex-direction: column;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    font-size: px2rem(18);
    padding: px2rem(20);
    word-break: break-word;
  }
  &-btn {
    position: absolute;
    bottom: px2rem(10);
    left: 50%;
    transform: translateX(-50%);
    font-size: px2rem(20);
    background: #a53434;
    padding: px2rem(7) px2rem(30);
    border-radius: px2rem(6);
    cursor: pointer;
  }
}
.burn {
  &-log {
    display: flex;
    color: #fff;
    line-height: px2rem(40);
    justify-content: space-between;
  }
}
.award {
  &-maintitle {
    color: #fff;
    font-size: px2rem(52);
    text-align: center;
    padding-top: px2rem(30);
  }
  &-country-box {
    position: relative;
  }
  &-select {
    position: absolute;
    width: 100%;
    height: px2rem(300);
    background: #000;
    overflow: auto;
    color: #fff;
    padding: px2rem(10) 0;
    z-index: 2;
    &::-webkit-scrollbar {
      width: px2rem(6);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(233, 230, 230, 0.7);
      border-radius: px2rem(8);
    }
    &::-webkit-scrollbar-track {
      background: #000;
      border-radius: px2rem(8);
    }
    &-item {
      padding: px2rem(5) px2rem(12);
      line-height: px2rem(32);
      cursor: pointer;
      &.active {
        background: #7da6ce;
      }
    }
  }
  &-info {
    color: #ccc;
    font-size: px2rem(20);
    text-align: center;
    width: 90%;
    margin: px2rem(40) auto;
    line-height: px2rem(36);
  }
  &-box {
    position: fixed;
    top: 50%;
    left: 50%;
    width: px2rem(1150);
    height: px2rem(720);
    transform: translate(-50%, -50%);
    padding: px2rem(20);
    @include bg;
    background-image: url('./assets/award.png');
    background-size: 100% 100%;
    box-sizing: border-box;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: px2rem(20);
  }
  &-input {
    @include bg;
    background-image: url('./assets/bg-info.png');
    background-color: transparent;
    width: px2rem(380);
    height: px2rem(32);
    padding: px2rem(8) px2rem(15);
    color: #7da6ce;
    font-size: px2rem(24);
    background-size: 100% 100%;
  }
  &-title {
    font-size: px2rem(28);
    color: #7da6ce;
    width: px2rem(200);
    text-align: right;
    margin-right: px2rem(40);
  }
  &-btn {
    @include bg;
    background-image: url('./assets/mint.png');
    width: px2rem(190);
    height: px2rem(50);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: px2rem(22);
    margin: 0 auto;
    cursor: pointer;
    // transform: translateX(px2rem(120));
  }
  &-close {
    width: px2rem(26);
    height: px2rem(26);
    position: absolute;
    right: px2rem(20);
    top: px2rem(20);
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      display: block;
      width: px2rem(22);
      height: px2rem(2);
      border-radius: px2rem(5);
      position: absolute;
      top: 50%;
      left: 50%;
      background: #fff;
      transform-origin: center;
      transform: translate(-50%, -50%);
      transition: transform 0.3s;
    }
    &:hover {
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
</style>
